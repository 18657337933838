@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 35px;

  margin: 60px auto 60px auto;
  width: min(1016px, 100%);
  padding: 0 30px;
}

.caption {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--marketplaceColor);
}

.heading {
  @apply --headlines2Font;
  margin: 0;
}

.startRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 9px;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.startBox {
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  border-radius: 18px;
  border: 1px solid #c2c2c2;
  flex-basis: 100%;

  background-color: #ffffff;
}

.providerLink {
  @apply --marketplaceButtonStylesPrimary;
  padding: 13px 0;
  text-align: center;
  min-height: 50px;
}

.customerLink {
  @apply --marketplaceButtonStyles;
  padding: 13px 0;
  min-height: 50px;
}

.bodyBold {
  @apply --bodyBoldFont;
}

.focus {
  @apply --focusFont;
}

.modal {
  @apply --marketplaceModalBaseStyles;
  margin: 25px 0 0 0;
}

.customerLogos {
  margin-top: 30px;
}
