@import '../../marketplace.css';

.desktopHeading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #333333;
  margin-top: 0;
}

.desktopHeadingWithEdit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.desktopHeadingWithEditNoHeading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.desktopHeadingWithEditNoHeading * {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.container {
  border: 1px solid #d9d9d980;
  border-radius: 18px;
  padding: 20px;
  background-color: var(--matterColorLight);
}

.containerNoHeading {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d9d9d980;
  border-radius: 18px;
  padding: 20px;
  background-color: var(--matterColorLight);
}

.desktopHeadingWithEdit > button {
  margin-bottom: 24px;
  word-spacing: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
