@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.bioNameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-bottom: 28px;
  padding-bottom: 12px;
  border-bottom: var(--matterColorNegative) 1px solid;
}

.desktopHeading {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 0;
  margin-top: 12px;
}

.desktopWhoamI {
  @apply --marketplaceH4FontStyles;
  margin-bottom: 12px;
  margin-top: 0;
  text-align: center;
}

.main {
  padding: 0 1em;
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    padding: 0 8em;
  }

  @media (--viewportXLarge) {
    width: 70%;
    margin: 0 auto;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  margin-top: 25px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 25px;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  margin-bottom: 20px;
}

.modifyServiceHeading {
  background-color: var(--marketplaceColorSuperLight);
  display: flex;
  margin-top: 25px;
  border-radius: 8px;
  padding: 0 20px;
  justify-content: center;

  @media (--viewportMedium) {
    justify-content: space-between;
  }

  /* @media (--viewportLarge) {
    margin: 25px calc((100% - 1256px) / 2);
  } */
}

.modifyServiceHeading span {
  display: flex;
  align-items: center;
  color: var(--matterColor);
  gap: 0.1em;
}

.modifyServiceTitle {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.error {
  color: var(--failColor);
}

.aside {
  width: 100%;
  background-color: var(--matterColorBright);
  padding: 0 8px;
  box-shadow: none;

  @media (--viewportMedium) {
    width: 324px;
    padding: 0;
  }
}

.aside > div {
  width: 100%;
}

.asideContent {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    flex-direction: column;
    /* justify-content: center; */
    position: sticky;
    top: 97px;
  }
}

.asideContent > h2 {
  margin: 0;
  margin-bottom: 15px;
  display: flex;
  gap: 15px;
  font-weight: 400;
  align-items: center;
}

.avatar {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  align-self: center;
}

.mobileHeading {
  flex-shrink: 0;
  margin-bottom: 0;
  font-size: 2em;
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.starIcon {
  fill: #ffd056;
  padding: 0 2px;
  margin-bottom: 3px;
  display: inline;
  width: 24px;
}

.mapMarkerIcon {
  fill: var(--marketplaceColor);
  padding: 0 2px;
  margin-bottom: 3px;
  display: inline;
  width: 24px;
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
}

.bio * {
  margin: 0;
  padding: 0;
}

.bio ul li {
  margin: 0;
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

.listingsContainer {
  @apply --clearfix;
  border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.listing {
  /* Single column in mobile */
  margin-top: 12px;
  width: 100%;

  @media (min-width: 1100px) {
    width: calc(50% - 20px);
    margin: 0;
  }

  @media (min-width: 1400px) {
    width: calc(33% - 20px);
    margin: 0;
  }
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  @media (--viewportMedium) {
    /* margin-bottom: 48px; */
  }

  @media (--viewportLarge) {
    /* max-width: 600px; */
    /* margin-bottom: 0; */
  }
}

.desktopReviewsTitle {
  margin-bottom: 1em;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.fontWeightRegular {
  font-weight: 400;
}

.containerItem {
  margin-bottom: 2em;
}

.euroSign {
  fill: var(--marketplaceColor);
}

.editProfileLinkDesktop {
  display: flex;
  align-items: center;
  gap: 8px;
}
