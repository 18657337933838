@import '../../marketplace.css';

.aboutMeContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;

  background-color: #ffffff;

  border: 1px solid #d9d9d980;
  border-radius: 18px;
}

.sectionTitle {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 0;
  color: #333333;
}

.bio {
  @apply --marketplaceBodyFontStyles;
  margin-bottom: 0;
  color: #333333;
}

.bio * {
  margin: 0;
  padding: 0;
}

.bio ul li {
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

.sectionSkillsAndTools,
.sectionHobbies {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid #d9d9d980;
  padding-top: 20px;
}

.skillsAndToolsHeading,
.hobbiesHeading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.skillsAndTools,
.hobbies {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skillContainer,
.hobbyContainer {
  border-radius: 100px;
  padding: 4px 12px;
  margin-top: 10px;
  background: #e5f0f3;

  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.toggleShowMore {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--marketplaceColor);
  cursor: pointer;
  margin-top: 6px;
  align-self: flex-end;
  padding-bottom: 3px;
}
