@import '../../marketplace.css';

.reviewsAndTestimonialsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  border-radius: 18px;
  padding: 30px;

  background-color: #e6f4f1;
}
.reviewsContainer,
.testimonialsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.reviewsHeading,
.testimonialsHeading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #333333;
}

.reviewsHeading {
  margin-bottom: 16px;
}

.testimonialsSubheading {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 16px;
}

.reviews,
.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
}

.review {
  background-color: #ffffff;
  border-radius: 18px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.testimonial {
  background-color: #ffffff;
  border-radius: 18px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.testimonialContent,
.reviewContent {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.testimonialAuthorInfo {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.testimonialAuthorName,
.testimonialTask {
  font-weight: 600;
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;
}

.reviewInfo {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.separator {
  margin: 0 7px;
  width: 16px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
    width: 12px;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 15px;
  width: 15px;
  margin-right: 6px;

  &:last-of-type {
    margin-right: 0;
  }
}

.toggleShowMore {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--marketplaceColor);
  cursor: pointer;
  margin-top: 16px;
  align-self: flex-start;
  padding-bottom: 3px;
}
