@import '../../marketplace.css';

.profileUserCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 18px;
  border-width: 1px;
  padding: 30px 20px;
  margin-bottom: 20px;
  width: 100%;

  background-color: #ffffff;

  border: 1px solid #d9d9d980;
}

.profileImageContainer {
  position: relative;
}

.profileImage {
}

.whoAmIContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.displayName {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
}

.whoAmI {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.reviewAndTestimonials {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 12px;
}

.reviews {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.starIcon {
  color: #ffd056;
}

.quoteIcon {
  color: var(--marketplaceColor);
}

.testimonials {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.contactButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--marketplaceColor);
  color: #ffffff;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  width: fit-content;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.sendIcon {
  color: #ffffff;
  width: 16px;
  height: 16px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #d9d9d980;
  margin: 6px 0;
}

.locationSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.mapMarkerIcon {
  color: var(--marketplaceColor);
  width: 18px;
  height: 18px;
}

.languageSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.languageIcon {
  color: var(--marketplaceColor);
  width: 18px;
  height: 18px;
}

.hourlyFeeSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.euroIcon {
  color: var(--marketplaceColor);
  width: 18px;
  height: 18px;
}

.cocSectionTitle {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.cocSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.cocContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
}

.cocIcon {
  width: 15px;
  height: 15px;
  color: var(--successColor);
  margin-top: 4px;
}
.cocIconNotAccepted {
  width: 15px;
  height: 15px;
  color: var(--matterColorAnti);
  margin-top: 4px;
}

.openCoc {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 28px;
  margin-top: 7px;
  color: var(--marketplaceColor);

  &:hover {
    cursor: pointer;
  }
}
