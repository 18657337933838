@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px 0px #0000001a;

  border-radius: 18px;
  padding: 16px;
  background-color: #ffffff;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.imageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  width: 30%;
  aspect-ratio: 3/2;

  border-radius: 6px;

  @media (--viewportSmall) {
    width: 23%;
  }
}

.rootForImage {
  height: 100%;
  width: 100%;
  /*border-radius: var(--borderRadius);*/
  border-radius: 6px;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 2px 6px 2px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  &:hover {
    text-decoration: none;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2em;

  /* Font */
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  color: var(--marketplaceColor);
}

.mainInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 100%; */
}

.title {
  /* Font */
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  color: var(--matterColor);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  hyphens: auto;
}

.authorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  &:hover {
    text-decoration: none;
  }
}

.authorContent {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  /* padding: 6px; */
  gap: 16px;
  &:hover {
    text-decoration: none;
  }
}

.avatarContainer {
  width: 70px;
  height: 70px;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
}

.authorName {
  display: flex;
  gap: 10px;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--matterColor);
}

.authorWhoAmI {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--matterColor);

  height: 50px;
  max-width: 250px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (--viewportSmall) {
    max-width: 400px;
  }
  @media (--viewportMedium) {
    max-width: 500px;
  }
}

.starIcon {
  fill: #ffd056;
  margin-bottom: 3px;
  display: inline;
  width: 24px;
  justify-self: self-start;
}

.favoriteWrapper {
  /* width: 26px; */
  height: 82px;
  padding-top: 3px;
  padding-right: 2px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  color: var(--marketplaceColor);
}

.favoriteWrapper * {
  width: 26px;
  height: 26px;

  &:hover {
    cursor: pointer;
  }
}

.reviewsHeading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: -4px;
}

.authorBio {
  height: 50px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  margin-top: 12px;
  padding-right: 36px;

  color: var(--matterColor);
  &:hover {
    text-decoration: none;
  }
}

.authorBio * {
  margin: 0;
  padding: 0;
}

.experienceContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 68px;
  min-height: 68px;
  padding: 16px 10px 16px 0;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.experience {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-height: 70px;
  gap: 14px;
}

.skillContainer {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  padding: 6px 10px;

  background-color: var(--marketplaceColorSuperLight);
  border-radius: 9px;

  min-width: fit-content;

  color: var(--matterColor);

  @media (--viewportSmall) {
    font-size: 16px;
  }
}

.experienceLeft {
  position: relative;
  z-index: 10;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  margin-left: 8px;
  min-width: 5%;

  color: var(--matterColor);

  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin-left: 16px;
  }
}

.experiencePopupHidden {
  display: none;
}

.experiencePopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px;
  border-radius: 9px;
  background-color: #ffffff;
  z-index: 20;
  top: -30px;
  right: 0;
  /* width: 100%; */
  max-width: fit-content;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
