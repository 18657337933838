@import '../../marketplace.css';

.proLogoSection {
  position: relative;
  top: -17px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #ffffff; */
  /* margin: 0 auto; */
  border-radius: 20px 20px 0 0;

  @media (--viewportMedium) {
    top: 0;
  }
}

.proLogoSectionDivider {
  position: relative;
  border-radius: 20px 20px 0 0;

  width: 300px;
  height: 1px;

  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
  /* margin-bottom: 46px; */

  @media (--viewportMedium) {
    top: -20px;
  }
}

.proLogosWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 24px;
  }
}

.proLogosOurCustomers {
  color: var(--matterColorAnti);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;

  position: relative;
  top: 40px;

  @media (--viewportMedium) {
    top: 0;
  }
}

.proLogosContainer {
  position: relative;
  display: flex;
  /* flex-direction: row; */
  height: 70px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  overflow: hidden;

  @media (--viewportMedium) {
    gap: 24px;
  }
}

.logoXSmall {
  height: 14px;
  width: auto;
  object-fit: contain;
}

.logoSmall {
  height: 18px;
  width: auto;
  object-fit: contain;
}

.logoMedium {
  height: 24px;
  width: auto;
  object-fit: contain;
}

.logoLarge {
  height: 28px;
  width: auto;
  object-fit: contain;
}

/* .proLandingPageLogoContainerMedium:nth-child(6), */
.proLandingPageLogoContainerMedium:nth-child(8) {
  display: none;

  @media (--viewportSmall) {
    width: 90px;
    height: 90px;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.proLandingPageLogoContainerMedium:nth-child(6) {
  display: none;

  @media (--viewportLarge) {
    width: 90px;
    height: 90px;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.proLandingPageLogoContainerMedium {
  width: 20%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (--viewportSmall) {
    width: 90px;
    height: 90px;
  }
}

.proLandingPageLogoContainerSmall {
  display: none;

  @media (--viewportSmall) {
    width: 70px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.proLandingPageLogoContainerZentuvo {
  width: 70px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.proLandingPageLogoContainerXSmall {
  display: none;

  @media (--viewportSmall) {
    width: 50px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
