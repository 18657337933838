@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.root input,
.root textarea {
  background: var(--matterColorLight);
}

.placeholderContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--matterColorAnti);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 2px;
}

.searchIcon {
  height: 16px;
  width: 16px;
}

.quillClass {
  background: var(--matterColorLight);
  height: 20em;
}

.submitContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  /* margin-top: auto; */
}

.submitContainerNoHeading {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: auto;
}

.backButton {
  @apply --marketplaceButtonStylesSecondary;
  max-width: 144px;
  min-height: 40px;
  padding: 8px 30px;
}

.nextButton {
  @apply --marketplaceButtonStyles;
  max-width: 144px;
  min-height: 40px;
  padding: 8px 30px;
}

.cocAcceptButton {
  @apply --marketplaceButtonStyles;
  max-width: 144px;
  min-height: 40px;
  padding: 8px 30px;
  background-color: var(--successColor);
}

.pointsHeader {
  font-weight: 600;
}

.pointerList {
  list-style: disc;
  margin: 0 0 0 20px;
}

.whoAmI > .pointerList {
  margin-top: 18px;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 18px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.fieldArray {
  font-weight: normal;

  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
}

.skillsFieldArray {
  font-weight: normal;

  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.skillsCategory {
  order: 2;

  @media (--viewportMedium) {
    order: 1;
  }
}

.skillsLevel {
  order: 2;
}

.skillsDelete {
  order: 1;
  align-self: flex-end;

  @media (--viewportMedium) {
    order: 3;
    align-self: auto;
  }
}

.fieldArrayOption {
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: 70%;
  }
}

.fieldArrayLevel {
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: 30%;
  }
}

.deleteIconContainer {
  height: fit-content;
}

.deleteIcon {
  cursor: pointer;
}

.selectedSkills {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 18px;
}

.selectedSkillNotActive {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 9px;
  border: solid 1px var(--matterColorNegative);
  background-color: #ffffff;
  color: var(--matterColor);
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.selectedSkillActive {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 9px;
  border: solid 1px var(--matterColorNegative);
  background-color: var(--marketplaceColor);
  color: #ffffff;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.addToArray {
  align-self: flex-start;
  padding: 0;
  line-height: 18px;

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.addToArrayDisabled {
  align-self: flex-start;
  padding: 0;
  line-height: 18px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
}

.mainHeading {
  @apply --headlines2Font;
  margin: 0 0 28px 0;
  display: flex;
  justify-content: space-between;
}

.mainHeadingNoMargin {
  @apply --headlines2Font;
  margin: 12px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.description {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  margin-top: -30px;
}

.skillsHeading {
  @apply --headlines2Font;
  margin: 0;
  display: flex;
  justify-content: space-between;

  @media (--viewportMedium) {
    margin-bottom: 28px;
  }
}

.subHeading {
  @apply --headlines2Font;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: space-between;
}

.subHeadingCOC {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.mainHeadingNoMargin {
  @apply --headlines2Font;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.bodyBold {
  @apply --bodyBoldFont;
  margin: 0;
}

.alignEnd {
  align-self: flex-end;
  margin-bottom: -24px;
}

.datesContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 42px;
  }
}

.datesItem {
  display: flex;
  flex-basis: auto;
  gap: 10px;

  @media (--viewportMedium) {
    flex-basis: 320px;
  }
}

.years {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.datesItem > * {
  flex: 1;
}

.textFieldMedium textarea {
  height: 80px;
}

.textFieldLarge textarea {
  height: 160px;
}

.workingMethodsContainer {
  display: flex;
  flex-direction: column;
  /* gap: 18px; */
}

.workingMethods {
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (--viewportMedium) {
    gap: 60px;
  }
}

.workingMethod {
  margin: 40px 0 30px 0;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  gap: 18px;
}

.nameContainerCOC {
  display: flex;
  flex-direction: row;
  gap: 18px;
  margin-top: 16px;
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.errorNoMargin {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 0;
  margin-bottom: 0;
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 44px;
    margin-bottom: 20px;
  }
}

.uploadAvatarWrapper label {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    margin: 0;
  }
}
.avatar {
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
  }

  &:hover {
    cursor: pointer;
  }
}

.infoList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 18px;
}

.infoListItem {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.infoListIcon {
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  color: var(--successColor);
}

.listItemTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.listItemText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.linkedInSuccessMessage p {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: var(--successColorDark);
}

.linkedInErrorMessage p {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: var(--failColor);
}

.twoColumnLayout {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.leftColumn {
  flex: 1;
  border-right: 1px solid #ccc;
  padding: 10px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 6px;
  padding: 10px;
}

.workExperienceList {
  list-style: none;
  gap: 6px;
  padding: 0;
}

.workExperienceItemContainer {
  display: flex;
  flex-direction: row;
}

.workExperienceItem {
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  width: 90%;
  padding: 10px;
  margin-bottom: 6px;
  margin-right: 8px;
  cursor: pointer;
}

.workExperienceItem.selected {
  border: 2px solid var(--marketplaceColor);

  font-weight: bold;
}

.workExperienceItem.invalid {
  border: 2px solid var(--failColor);
}

.addButton {
  margin-top: 20px;
}

.noIndexingContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 18px;
}

.noIndexingInfo {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.incompleteFieldsMessage {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--failColor);
}
