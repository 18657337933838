@import '../../marketplace.css';

.cocModal {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 18px;

  @media (--viewportMedium) {
    width: 700px;
  }
}

.listItemTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.listItemText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
